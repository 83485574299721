import { useRollbar } from '@rollbar/react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { twJoin, twMerge } from 'tailwind-merge'
import VideoLinkIcon from 'shared/icons/video-link-icon'
import SelectUrlModal from 'modules/attachments/components/select-url-modal'
import { fetchOembed } from 'modules/attachments/utils/attachment-utils'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'
import { ERROR_FORBIDDEN } from '../const/attachments-consts'
import { usePostAttachmentsContext } from './context'

export interface EmbeddedVideoAttachmentProps {
  className?: string
  iconClassName?: string
}
const EmbeddedVideoAttachment = ({
  className,
  iconClassName = '',
}: EmbeddedVideoAttachmentProps) => {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { addAttachment, updateAttachment } = usePostAttachmentsContext()

  const handleEmbedVideo = async (url: string) => {
    const attachmentId = addAttachment({
      id: crypto.randomUUID(),
      status: AttachmentUploadStatusEnum.Uploading,
      url,
      previewUrl: null,
      type: AttachmentTypeEnum.EmbeddedVideo,
    })

    try {
      const videoMetadata = await fetchOembed(url)
      if ('error' in videoMetadata) {
        videoMetadata.error === ERROR_FORBIDDEN
          ? toast.error(t('post.attachments.upload_unauthorized'))
          : toast.error(t('post.attachments.upload_failed'))
      }

      if ('error' in videoMetadata) {
        throw new Error(videoMetadata.error)
      }

      updateAttachment(attachmentId, {
        status: AttachmentUploadStatusEnum.Success,
        previewUrl: videoMetadata.thumbnail_url,
      })
    } catch (error) {
      rollbar.error('failed to upload embedded video url', error as Error)
      updateAttachment(attachmentId, {
        status: AttachmentUploadStatusEnum.Failed,
      })
    }
  }

  return (
    <>
      <button onClick={() => setIsModalOpen(true)} type="button" className={className}>
        <VideoLinkIcon
          className={twMerge(`h-6 w-6 text-lightgray hover:text-darkblue`, iconClassName)}
        />
      </button>
      <SelectUrlModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={handleEmbedVideo}
      />
    </>
  )
}

export default EmbeddedVideoAttachment
