import { twMerge } from 'tailwind-merge'

interface LoadMoreButtonProps extends React.ComponentProps<'button'> {
  onClick: () => void
  className?: string
}

export const LoadMoreButton = ({ children, className, ...rest }: LoadMoreButtonProps) => {
  return (
    <button
      className={twMerge(
        'mx-auto my-2 block cursor-pointer p-1.5 text-center text-base font-medium transition-colors hover:text-blue',
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
