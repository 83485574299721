import { getExtensionFromMimeType } from './get-extension-from-mime-type'
import { getFilenameFromContentDisposition } from './get-filename-from-content-disposition'
import { getFilenameFromUrl } from './get-filename-from-url'

/**
 * Function to determine the filename of an attachment.
 *
 * @param {string} url - The attachment URL.
 * @param {object} [options] - Additional data, if already obtained.
 * @param {string | null} [options.contentDisposition] - Value of the Content-Disposition header.
 * @param {string | undefined} [options.contentType] - Value of the Content-Type header.
 * @returns {string} - The determined filename.
 *
 * @example
 * // Basic usage with just a URL
 * const filename = getAttachmentFilename('https://example.com/images/photo.jpg');
 * // Output: photo.jpg
 *
 * @example
 * // With Content-Disposition header
 * const filename = getAttachmentFilename(
 *   'https://example.com/download/file',
 *   { contentDisposition: 'attachment; filename="document.pdf"' }
 * );
 * // Output: document.pdf
 *
 * @example
 * // URL without extension + Content-Type
 * const filename = getAttachmentFilename(
 *   'https://example.com/files/document',
 *   { contentType: 'application/pdf' }
 * );
 * // Output: document.pdf
 */
export function getAttachmentFilename(
  url: string,
  options: { contentDisposition: string | null; contentType?: string } = {
    contentDisposition: null,
  },
) {
  let filename = null

  // 1. If Content-Disposition header is provided, try to extract the filename from it
  if (options.contentDisposition) {
    filename = getFilenameFromContentDisposition(options.contentDisposition)
  }

  // 2. If filename is not obtained, extract it from the URL
  if (!filename) {
    filename = getFilenameFromUrl(url)
  }

  // 3. If the filename doesn't have an extension, add it based on MIME-type (if known)
  if (!/\.[0-9a-z]+$/i.test(filename)) {
    const extension = options.contentType ? getExtensionFromMimeType(options.contentType) : '.jpg'
    filename += extension
  }

  return filename
}
