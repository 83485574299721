import axios from 'axios'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import {
  COMMUNITY_FILE_SOURCE,
  DATA_FILES_API_BASE_PATH,
  DATA_FILES_VALIDATION_API_PATH,
} from 'modules/attachments/const/attachments-consts'

export interface UploadOptionsInterface {
  key: string
  acl: string
  credential: string
  algorithm: string
  date: string
  policy: string
  signature: string
  contentType: string
  cacheControl: string
  url: string
}

export interface FileDataInterface {
  name: string
  size: number
  type?: string
}

export interface FileInfoInterface {
  id: number
  path: string
  size: number
  tags: string[]
  type: string
  filename: string
  filenameWithoutHash: string
  thumbnail: string
  name?: string
}

export interface UploadFile {
  file: File
  uploadOptions: UploadOptionsInterface
  signal?: AbortSignal
}

const useUploadFile = () => {
  const { fetcher: validateFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    { uploadOptions: UploadOptionsInterface; id: number }
  >({
    method: RequestMethodsEnum.post,
    badRequestHandler: () => {},
    notFoundResponseHandler: () => {},
  })

  const { fetcher: uploadFetcher, isFetching: isUploading } =
    useApiWrapperWithErrorValidation<RequestMethodsEnum.post>({
      method: RequestMethodsEnum.post,
      badRequestHandler: () => {},
      notFoundResponseHandler: () => {},
    })

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    { [key: string]: FileInfoInterface }
  >({
    method: RequestMethodsEnum.post,
    badRequestHandler: () => {},
    notFoundResponseHandler: () => {},
  })

  const validateAndGetUploadOptions = async ({
    file,
    mimeType,
    signal,
  }: {
    file: FileDataInterface
    mimeType?: string
    signal?: AbortSignal
  }) =>
    validateFetcher(
      DATA_FILES_VALIDATION_API_PATH,
      {
        source: COMMUNITY_FILE_SOURCE,
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type || mimeType,
        isCommon: false,
      },
      { signal },
    )

  const uploadFile = async ({ file, uploadOptions }: UploadFile) =>
    axios.postForm(uploadOptions.url, {
      key: uploadOptions.key,
      acl: uploadOptions.acl,
      'X-Amz-Credential': uploadOptions.credential,
      'X-Amz-Algorithm': uploadOptions.algorithm,
      'X-Amz-Date': uploadOptions.date,
      Policy: uploadOptions.policy,
      'X-Amz-Signature': uploadOptions.signature,
      'Content-Type': uploadOptions.contentType,
      'Cache-Control': uploadOptions.cacheControl,
      file: file,
    })

  const activateFile = async (dataFile: number) =>
    fetcher(`${DATA_FILES_API_BASE_PATH}/${dataFile}/activate-file`, { optimize: true })

  return {
    validateAndGetUploadOptions,
    uploadFile,
    activateFile,
    isUploading,
  }
}

export default useUploadFile
