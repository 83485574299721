import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { isKeyHotkey } from 'is-hotkey'
import { useTranslation } from 'next-i18next'
import { Descendant, Editor, Range, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { twJoin, twMerge } from 'tailwind-merge'
import { useClickOutside } from 'shared/hooks/use-click-outside'
import { useDebouncedValue } from 'shared/hooks/use-debounced-value'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { SendIcon } from 'shared/icons/send-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import AttachmentsPreview from 'modules/attachments/components/preview'
import { CommentInterface, Suggestion } from 'modules/comments/components/comment/comment.types'
import { EditorMode } from 'modules/comments/types'
import { isCommunityExtra } from 'modules/comments/utils/is-community-extra'
import { useSuggestionsList } from 'modules/community/api/use-suggestions-list'
import { ParagraphElement } from 'modules/community/types/CustomEditor'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { useCommentsContext } from '../comments-list'
import { MentionSuggestions } from './elements/SuggestionMention/SuggestionMention'
import { Element } from './elements/element'
import { Leaf } from './elements/leaf'
import withLinks from './plugins/withLinks'
import { withMentions } from './plugins/withMentions'
import { Toolbar } from './toolbar'
import { insertMention } from './utils/mention'
import { serializeHTML } from './utils/serialize'

export interface CommentEditorProps {
  handleAddComment: (value: string, attachments?: PostAttachmentType[]) => void
  setActiveComment: (comment: CommentInterface | null) => void
  isRoot: boolean
  initialValue?: Descendant[]
  mode?: EditorMode
  onCancel?: () => void
  className?: string
  editableComment?: CommentInterface
}

export const CommentEditor = ({
  handleAddComment,
  setActiveComment,
  isRoot,
  initialValue,
  mode = EditorMode.Add,
  onCancel,
  className = '',
  editableComment,
}: CommentEditorProps) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { extra } = useCommentsContext()
  const [serializedTextHtml, setSerializedTextHtml] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const editor = useMemo(() => withReact(withHistory(withLinks(withMentions(createEditor())))), [])
  const { attachments, isUploading } = usePostAttachmentsContext()

  const [target, setTarget] = useState<Range | null>(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 300)
  const containerRef = useClickOutside(() => setIsFocused(false))

  const hasContent = serializedTextHtml && serializedTextHtml !== '<br>'
  const showExpandedView = isFocused || hasContent
  const isCommunityComments = isCommunityExtra(extra)

  const getSuggestionsListQuery = useSuggestionsList({
    communityPath: isCommunityComments ? extra.community.communityPath : undefined,
    postId: isCommunityComments ? extra.community.postId : undefined,
    displayNameSearchBy: debouncedSearch,
  })

  const [value, setValue] = useState<Descendant[]>(
    initialValue || [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
  )

  const renderElement = useCallback((props: any) => <Element {...props} />, [])

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />
  }, [])

  const handleSendComment = () => {
    if (serializedTextHtml) {
      handleAddComment(serializedTextHtml, attachments)

      if (mode === EditorMode.Edit) return

      Transforms.select(editor, Editor.start(editor, []))
      editor.history = { redos: [], undos: [] }
      const emptyParagraph: ParagraphElement = {
        type: 'paragraph',
        children: [{ text: '' }],
      }
      editor.children = [emptyParagraph]
    }
  }

  const handleCancel = () => {
    if (onCancel) return onCancel()

    setActiveComment(null)
  }

  useEffect(() => {
    const textHtml = serializeHTML(editor)
    if (!textHtml) {
      setSerializedTextHtml('')
    } else {
      setSerializedTextHtml(textHtml)
    }
  }, [value])

  const updateEditorContent = (value: Descendant[]) => {
    setValue(value)
    const { selection } = editor

    // NOTE: This code for a display list of mentions when typing '@'
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/(?:^|\s)@([^\s@]*)(\s|$)/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      const twoCharsBefore =
        wordBefore &&
        Editor.before(
          editor,
          { ...wordBefore, offset: wordBefore.offset },
          { unit: 'character', distance: 2 },
        )
      const twoCharsRange = twoCharsBefore && Editor.range(editor, twoCharsBefore, start)
      const twoCharsText = twoCharsRange && Editor.string(editor, twoCharsRange)

      const isEmptyBeforeAtRegular = new RegExp(/(?:^|\s)@/)
      const isEmptyBeforeAt = isEmptyBeforeAtRegular.test(twoCharsText || '')

      if (beforeMatch?.[1] && afterMatch && isEmptyBeforeAt) {
        // NOTE: This is for contains mention '@asd'
        setTarget(beforeRange || twoCharsRange || null)
        setSearch(beforeMatch[1])
        return
      } else {
        // NOTE: This is for empty mention '@'
        const pointBefore = Editor.before(editor, start, { distance: 2 })
        const pointRange = pointBefore && Editor.range(editor, pointBefore, start)
        const characterBefore = pointRange && Editor.string(editor, pointRange)

        const pointNormal = Editor.before(editor, start)
        const pointNormalRange = pointNormal && Editor.range(editor, pointNormal, start)

        if (characterBefore === ' @' || characterBefore === '@') {
          setTarget(pointNormalRange || pointRange || beforeRange || null)
          setSearch('')
          return
        }
      }
    }

    setTarget(null)
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const { selection } = editor

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    // Reference from official Slate example: https://github.com/ianstormtaylor/slate/blob/6bc57583587d88b26fb03e054511b6d7ac23ef7c/site/examples/ts/inlines.tsx#L74
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }
  }

  const focusEditor = () => {
    if (!isFocused || !ReactEditor.isFocused(editor)) {
      ReactEditor.focus(editor)
    }
  }

  const handleMentionSelect = (mention: Suggestion) => {
    if (target) {
      insertMention(
        editor,
        {
          displayName: mention.displayName,
          id: mention.userId,
          profileImageUrl: mention.profileImageUrl,
        },
        target,
      )
      setTarget(null)
      ReactEditor.focus(editor)
    }
  }

  useEffect(() => {
    if (!editableComment) return

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCancel()
      }
    }

    document.addEventListener('keydown', handleEscKey)
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [editableComment])

  return (
    <Slate editor={editor} initialValue={value} onChange={updateEditorContent}>
      <div className={className}>
        <div className="flex flex-row items-start gap-2.5 sm:gap-2.5">
          {isAuthorizedUser(user) && (
            <img className="mt-2 h-10 w-10 rounded-full" src={user.avatarUrl} />
          )}

          <div
            ref={containerRef}
            className={twMerge(
              'relative w-[calc(100%-40px)] min-w-0 grow-0 cursor-text rounded-xl border border-transparent bg-[#F0F3F6] p-3 transition-all duration-200',
              (showExpandedView || mode === EditorMode.Edit) && 'p-5 pb-12 pr-14',
              'has-[:focus-visible]:border-blue',
            )}
            onClick={focusEditor}
          >
            <Editable
              className="peer text-darkblue focus-visible:outline-none [&_a:hover]:underline [&_a]:text-[#2e6ef4] [&_a]:no-underline"
              placeholder={t('comments.components.comments.input_placeholder_label')}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              style={{ whiteSpace: 'break-spaces' }}
              onKeyDown={onKeyDown}
              onFocus={() => setIsFocused(true)}
              scrollSelectionIntoView={() => {
                // Do nothing because it is trigger scrolling window
                // see: https://docs.slatejs.org/libraries/slate-react/editable#scrollselectionintoview-editor-reacteditor-domrange-domrange-greater-than-void
              }}
            />
            <AttachmentsPreview className={twJoin(!!attachments?.length && 'pt-4')} />

            <Toolbar
              className={twJoin(
                'absolute bottom-2.5 left-3.5',
                !(showExpandedView || mode === EditorMode.Edit) && 'hidden',
              )}
              path={isCommunityComments ? extra.community.communityPath : undefined}
            />
            {(showExpandedView || mode === EditorMode.Edit) && (
              <>
                {mode !== EditorMode.Edit && !isRoot && (
                  <button
                    className="absolute bottom-2.5 right-3.5 block cursor-pointer text-sm text-blue hover:text-blue-300 md:hidden"
                    onClick={handleCancel}
                  >
                    {t('comments.components.comments.cancel_label')}
                  </button>
                )}
                <button
                  className="absolute right-6 top-5 flex h-6 w-6 items-center justify-center text-blue disabled:text-[#98A2B3]"
                  onClick={handleSendComment}
                  disabled={!serializedTextHtml || serializedTextHtml === '<br>' || isUploading}
                >
                  <SendIcon className="size-5 md:size-6" />
                </button>
              </>
            )}
            {isCommunityComments &&
              extra.community.communityPath &&
              target &&
              (getSuggestionsListQuery.isLoading || !!getSuggestionsListQuery.data?.length) && (
                <MentionSuggestions
                  target={target}
                  editor={editor}
                  items={getSuggestionsListQuery.data || []}
                  isLoading={getSuggestionsListQuery.isLoading}
                  onSelect={mention => handleMentionSelect(mention)}
                />
              )}
          </div>
        </div>
        {(!isRoot || mode === EditorMode.Edit) && (
          <button
            onClick={handleCancel}
            className="ml-12 hidden cursor-pointer pt-2.5 text-sm text-darkblue/50 transition-colors hover:text-darkblue md:inline-block"
            dangerouslySetInnerHTML={{
              __html: t('comments.components.comments.cancel_label_or_esc'),
            }}
          />
        )}
        {mode === EditorMode.Edit && (
          <div className="mt-2.5 flex items-center justify-end">
            <button
              className="z-[1] flex min-h-[36px] w-auto min-w-[80px] cursor-pointer select-none items-center justify-end self-center rounded bg-blue px-5 text-center text-sm font-bold text-white outline-0 md:hidden"
              onClick={handleCancel}
            >
              {t('comments.components.comments.cancel_label')}
            </button>
          </div>
        )}
      </div>
    </Slate>
  )
}
