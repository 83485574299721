/**
 * Extracts filename from Content-Disposition HTTP header
 *
 * @param headerValue - The value of the Content-Disposition header (e.g. "attachment; filename=example.pdf")
 * @returns The extracted filename without quotes, or null if no filename is found or header is null
 *
 * @example
 * // Returns "example.pdf"
 * getFilenameFromContentDisposition('attachment; filename="example.pdf"');
 *
 * // Returns "report.xlsx"
 * getFilenameFromContentDisposition('attachment; filename=report.xlsx');
 *
 * // Returns null
 * getFilenameFromContentDisposition(null);
 */
export const getFilenameFromContentDisposition = (headerValue: string | null) => {
  if (!headerValue) return null
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = regex.exec(headerValue)
  if (matches && matches[1]) {
    return matches[1].replace(/['"]/g, '')
  }
  return null
}
