export enum AttachmentTypeEnum {
  Image = 'image_upload',
  Video = 'video_upload',
  Audio = 'audio_upload',
  EmbeddedVideo = 'video_link',
  EmbeddedImage = 'image_link',
  EmbeddedGif = 'gif_link',
}

export enum AttachmentUploadStatusEnum {
  Uploading = 'Uploading',
  Success = 'Uploaded',
  Failed = 'Failed',
}

export interface PostAttachmentInterface {
  id: string | number
  type: AttachmentTypeEnum
  status?: AttachmentUploadStatusEnum
  error?: string
  url?: string
  previewUrl?: string | null
  createdAt?: string
  metadata?: string
  content?: string
  dataFileId?: number
}

export interface ImageUploadAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.Image
  status: AttachmentUploadStatusEnum
  url?: string // uploaded only
  previewUrl?: string // uploaded only
  dataFileId?: number // uploaded only
}

export interface EmbeddedImageAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.EmbeddedImage
  status: AttachmentUploadStatusEnum
  previewUrl?: string // uploaded only
  url?: string // uploaded only
}

export interface AudioUploadAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.Audio
  status: AttachmentUploadStatusEnum
  url?: string // uploaded only
  dataFileId?: number // uploaded only
  name?: string
}

export interface VideoUploadAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.Video
  status: AttachmentUploadStatusEnum
  url?: string // uploaded only
  dataFileId?: number // uploaded only
}

export interface EmbeddedVideoAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.EmbeddedVideo
  status: AttachmentUploadStatusEnum
  createdAt?: string // uploaded only
  url: string
  previewUrl: string | null
}

export interface GifAttachment extends PostAttachmentInterface {
  type: AttachmentTypeEnum.EmbeddedGif
  status: AttachmentUploadStatusEnum
  url: string
  previewUrl: string
}

export type PostAttachmentType =
  | EmbeddedVideoAttachment
  | ImageUploadAttachment
  | EmbeddedImageAttachment
  | VideoUploadAttachment
  | GifAttachment
  | AudioUploadAttachment

export type PostAttachmentsApiCreateInterface =
  | Pick<ImageUploadAttachment, 'type' | 'dataFileId'>
  | Pick<VideoUploadAttachment, 'type' | 'dataFileId'>
  | Pick<EmbeddedVideoAttachment, 'type' | 'url' | 'previewUrl'>
  | Pick<EmbeddedImageAttachment, 'type' | 'url'>
  | Pick<GifAttachment, 'type' | 'url' | 'previewUrl'>
  | Pick<AudioUploadAttachment, 'type' | 'dataFileId' | 'name'>
