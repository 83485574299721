import { useRollbar } from '@rollbar/react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { PAGINATION_ORDER } from 'shared/constants/api'
import { usePageComments } from 'modules/comments/api/get-page-comments'
import { MessageBox } from 'modules/comments/components/message-box/message-box'
import { StickyMessageBox } from 'modules/comments/components/message-box/stycky-message-box'
import { CommentHighlightProvider } from 'modules/comments/contexts/comment-highlight'
import { LoadMoreButton } from 'modules/comments/ui/load-more-button'
import { findLastComment } from 'modules/comments/utils/find-last-comment'
import { mergeComments } from 'modules/comments/utils/merge-comments'
import { Comment } from '../comment'
import { CommentInterface } from '../comment/comment.types'
import { StickyMessageBoxWithReply } from '../message-box/stycky-message-box-with-reply'
import { CommentsContextType, CommentsProps, UpdateRepliesOptions } from './CommentsList.types'
import { parseParentIdFromKey } from './utils'

export const CommentsContext = createContext({} as CommentsContextType)
export const useCommentsContext = () => useContext(CommentsContext)

export const CommentsList = (props: CommentsProps) => {
  const {
    commentsType,
    shouldFetch = true,
    readOnly = false,
    onError,
    locale = 'en',
  }: CommentsProps = props

  const rollbar = useRollbar()
  const { t } = useTranslation()

  const [activeComment, setActiveComment] = useState<CommentInterface | null>(null)
  const [comments, setComments] = useState<CommentInterface[]>([])
  const [replies, setReplies] = useState<{
    [parentCommentId: number]: { replies: CommentInterface[]; isEnd: boolean; isLoading: boolean }
  }>({})
  const [requestParams, setRequestParams] = useState<{
    lastCommentId: number
    parentId: number | null
  }>({ lastCommentId: 0, parentId: null })
  const [isEnd, setIsEnd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const isCommunityProps = 'community' in props
  const resourceId = isCommunityProps ? props.community.postId : props.course.pageId
  const isReplyMessageBox = activeComment !== null
  const isAllowCommentingAndNotReplyMessageBox = !isReplyMessageBox && !readOnly

  const getPageCommentsQuery = usePageComments({
    parentId: requestParams.parentId,
    commentsType,
    pageId: resourceId,
    lastCommentId: requestParams.lastCommentId,
    sortOrder: isCommunityProps ? PAGINATION_ORDER.ASC : PAGINATION_ORDER.DESC,
    config: {
      revalidateOnMount: shouldFetch,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (data, key) => {
        const { isReply, parentId } = parseParentIdFromKey(key)

        const isPaginatedResponse = !Array.isArray(data) && 'items' in data
        const dataItems = isPaginatedResponse ? data?.items : data
        const hasMore = isPaginatedResponse ? data?.hasMore : false

        if (isReply) {
          updateReplies(parentId, {
            newReplies: mergeComments(
              [...(replies[parentId]?.replies ?? [])],
              dataItems,
              isCommunityProps,
            ),
            isEnd: !hasMore,
            isLoading: false,
          })
        } else {
          setComments(prev => mergeComments(prev, dataItems, isCommunityProps))
          dataItems.forEach(comment => {
            const childrenItems = Array.isArray(comment.children)
              ? comment.children
              : comment.children?.items
            const hasMore = Array.isArray(comment.children) ? false : comment.children?.hasMore

            if (childrenItems) {
              updateReplies(comment.id, {
                newReplies: childrenItems,
                isEnd: !hasMore,
              })
            }
          })
          setIsEnd(!hasMore)
          setIsLoading(false)
        }
      },
      onError: (error, key) => {
        const { isReply, parentId } = parseParentIdFromKey(key)

        if (isReply) {
          updateReplies(parentId, { newReplies: [], isEnd: true, isLoading: false })
        } else {
          setIsEnd(true)
          setIsLoading(false)
        }

        onError && onError(error)
        rollbar.error('Comments error', error)
      },
    },
  })

  useEffect(() => {
    if (!activeComment) return
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
        setActiveComment(null)
      }
    }
    document.addEventListener('keydown', handleEscKey)
    return () => document.removeEventListener('keydown', handleEscKey)
  }, [activeComment])

  const handleLoadMoreCommentsClick = () => {
    if (!isEnd && !isLoading) {
      setIsLoading(true)
      const lastNonNewComment = findLastComment(comments)
      setRequestParams({
        parentId: null,
        lastCommentId: lastNonNewComment?.id ?? 0,
      })
    }
  }

  const updateReplies = useCallback((commentId: number, options: UpdateRepliesOptions = {}) => {
    setReplies(prev => {
      const prevComment = prev[commentId] || {
        replies: [] as CommentInterface[],
        isEnd: false,
        isLoading: false,
      }
      return {
        ...prev,
        [commentId]: {
          replies: options.newReplies ?? prevComment.replies,
          isEnd: options.isEnd ?? prevComment.isEnd,
          isLoading: options.isLoading ?? prevComment.isLoading,
        },
      }
    })
  }, [])

  const loadMoreReplies = useCallback(
    ({ lastCommentId, parentId }: { lastCommentId: number; parentId: number }) => {
      updateReplies(parentId, { isEnd: false, isLoading: true })
      setRequestParams({ lastCommentId, parentId })
    },
    [updateReplies],
  )

  const replyInfo =
    !!activeComment && isCommunityProps
      ? {
          userId: activeComment.userId,
          displayName: activeComment.userName,
          profileImage: activeComment.userAvatarUrl,
        }
      : undefined

  const extraProviderValue = isCommunityProps
    ? {
        community: {
          communityPath: props.community.communityPath,
          postId: props.community.postId,
        },
      }
    : {
        course: {
          pageId: props.course.pageId,
        },
      }

  const activeCommentParentId =
    (!Array.isArray(activeComment?.parent) ? activeComment?.parent.id : activeComment?.id) || null

  return (
    <CommentsContext.Provider
      value={{
        commentsType,
        commentsList: comments,
        setComments,
        repliesList: replies,
        updateReplies,
        readOnly,
        extra: extraProviderValue,
      }}
    >
      <CommentHighlightProvider>
        <div className="relative">
          <div className="flex flex-col items-stretch space-y-5 md:space-y-6">
            {!isCommunityProps && isAllowCommentingAndNotReplyMessageBox && (
              <MessageBox parentId={null} onError={onError} />
            )}

            {comments?.map((comment: CommentInterface) => (
              <Comment
                key={comment.id}
                data={comment}
                level={0}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                onError={onError}
                replies={replies[comment.id]?.replies}
                loadMoreReplies={loadMoreReplies}
              />
            ))}
          </div>

          {!isEnd && (
            <LoadMoreButton onClick={handleLoadMoreCommentsClick}>
              {t('comments.components.comments.load_more_label')}
            </LoadMoreButton>
          )}

          {/* NOTE: It's a workaround to have the comment field re-created due to React life-cycle. Since Slate is uncontrollable and we can only use initValue on mount component */}
          {!isCommunityProps ? null : isAllowCommentingAndNotReplyMessageBox ? (
            <StickyMessageBox parentId={null} onError={onError} />
          ) : (
            <StickyMessageBoxWithReply
              parentId={activeCommentParentId}
              setActiveComment={setActiveComment}
              onError={onError}
              replyInfo={replyInfo}
              setResponses={(parentId, newReply) =>
                updateReplies(parentId, {
                  newReplies: isCommunityProps
                    ? [...(replies[parentId]?.replies ?? []), newReply]
                    : [newReply, ...(replies[parentId]?.replies ?? [])],
                })
              }
            />
          )}
        </div>
      </CommentHighlightProvider>
    </CommentsContext.Provider>
  )
}
