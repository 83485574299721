import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

export interface LoaderProps {
  small?: boolean
  type?: 'light' | 'dark'
  className?: string
  style?: CSSProperties
  iconClassName?: string
}

export const AudioLoaderFile = ({
  small = false,
  type = 'light',
  className,
  style,
  iconClassName,
}: LoaderProps) => (
  <div role="status" className={className} style={style}>
    <svg
      aria-hidden="true"
      className={twMerge(
        small ? 'h-5 w-5' : 'h-8 w-8',
        type === 'light' ? 'fill-blue text-white' : 'fill-white text-darkblue',
        iconClassName,
      )}
      viewBox="0 0 86 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="86" height="10" rx="5" fill="#ECECEC" />
      <rect x="0" y="1" height="8" rx="4" fill="#00A0FF" className="animate-fillBar" />
      <rect x="1" y="1" width="8" height="8" rx="4" fill="#00A0FF" className="animate-moveCircle" />
    </svg>
    <span className="text-AvertaPE text-xs text-lightgray-100">Uploading...</span>
  </div>
)
