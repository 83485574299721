import { twMerge } from 'tailwind-merge'
import { AudioPreviewLoader } from 'shared/components/audio-loader'
import {
  AttachmentUploadStatusEnum,
  AudioUploadAttachment,
} from 'modules/community/types/post-attachment'

interface AudioAttachmentPreviewProps {
  className?: string
  attachment: AudioUploadAttachment
  size?: 'small' | 'original'
}

export default function AudioAttachmentPreview({
  attachment,
  className,
  size = 'small',
}: AudioAttachmentPreviewProps) {
  return (
    <div
      className={twMerge(
        'flex-center flex h-full w-full overflow-hidden rounded-lg',
        attachment.status === AttachmentUploadStatusEnum.Uploading && 'bg-slate-100',
        className,
      )}
    >
      {attachment.status !== AttachmentUploadStatusEnum.Uploading && (
        <div className="w-full">
          <AudioPreviewLoader className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] md:h-8 md:w-8 lg:h-16 lg:w-16" />
          <span className="flex h-[90%] flex-col items-center justify-end text-xs text-lightgray-100">
            {attachment.name}
          </span>
        </div>
      )}
    </div>
  )
}
