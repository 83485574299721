import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { COMMENT_API_PREFIX } from 'shared/api/constants/api-constants'
import { adaptFetcherToSWRMutationFn } from 'shared/api/utils/adapt-fetcher-to-swr-mutation'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { AddCommentRequestType } from 'modules/comments/api/types'
import { CommentInterface } from 'modules/comments/components/comment/comment.types'
import { CommentsTypeEnum } from 'modules/comments/types'

interface UseAddCommentOptions<T extends CommentsTypeEnum> {
  config?: SWRMutationConfiguration<CommentInterface, Error, string, AddCommentRequestType<T>>
}

export const useAddComment = <T extends CommentsTypeEnum>({
  config,
}: UseAddCommentOptions<T>) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.post, CommentInterface, AddCommentRequestType<T>>({
    method: RequestMethodsEnum.post,
  })

  return useSWRMutation(COMMENT_API_PREFIX, adaptFetcherToSWRMutationFn(fetcher), config)
}
