import { twMerge } from 'tailwind-merge'
import { useIntersection } from 'shared/hooks/use-intersection'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import { CommentEditor } from '../comment-editor'
import { MessageBoxProps } from './message-box.types'
import { useCommentBox } from './use-comment-box'

export const StickyMessageBoxWithReply = ({
  parentId,
  setActiveComment = () => {},
  setResponses = () => {},
  setOpenedResponses = () => {},
  onError,
  level = 0,
  replyInfo,
}: MessageBoxProps) => {
  const { ref: visibilityRef, entry } = useIntersection({
    threshold: 1,
  })

  const { handleAddComment, attachmentsRef, setAttachments, initialValue } = useCommentBox({
    parentId,
    level,
    setActiveComment,
    setResponses,
    setOpenedResponses,
    onError,
    replyInfo,
  })

  const isSticky = !entry?.isIntersecting

  return (
    <PostAttachmentsProvider ref={attachmentsRef} isEditable onChange={setAttachments}>
      <div ref={visibilityRef} className="invisible absolute bottom-0 left-0 h-1 w-1" />
      <CommentEditor
        className={twMerge(
          'sticky bottom-0 left-0 z-10 -mx-8 mt-4 w-[calc(100%+32px)] bg-white px-2 py-2 sm:-mx-6 sm:w-[calc(100%+48px)] sm:px-6 md:-mx-8 md:w-[calc(100%+64px)]',
          isSticky && 'shadow-[0_-2px_2px_-1px_rgba(0,0,0,0.1)]',
        )}
        onCancel={() => setActiveComment(null)}
        handleAddComment={handleAddComment}
        setActiveComment={setActiveComment}
        isRoot={false}
        initialValue={initialValue}
      />
    </PostAttachmentsProvider>
  )
}
