import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { COMMENT_API_PREFIX } from 'shared/api/constants/api-constants'
import { adaptFetcherToSWRMutationFn } from 'shared/api/utils/adapt-fetcher-to-swr-mutation'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'

interface UseLikeCommentOptions {
  commentId: number
  config?: SWRMutationConfiguration<void, Error, string, undefined>
}

export const useLikeComment = ({ commentId, config }: UseLikeCommentOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.post, void, void>({
    method: RequestMethodsEnum.post,
  })

  return useSWRMutation(
    `${COMMENT_API_PREFIX}/${commentId}/like`,
    adaptFetcherToSWRMutationFn<void, undefined>(fetcher),
    config,
  )
}
