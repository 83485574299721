export const COMMUNITY_FILE_SOURCE = 'community'

export const DATA_FILES_API_BASE_PATH = '/api/data-files'
export const DATA_FILES_VALIDATION_API_PATH = `${DATA_FILES_API_BASE_PATH}/validate-and-get-upload-options`

export const NOEMBED_BASE_URL = 'https://noembed.com/embed'

// https://docs.wistia.com/docs/wistia-and-oembed#the-regex
export const WISTIA_URL_REGEXP = /https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/
export const WISTIA_OEMBED_API_URL = 'https://fast.wistia.com/oembed'

export const ERROR_FORBIDDEN = '403 Forbidden'
