import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'
import { COMMENT_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { LikeInterface } from 'modules/comments/components/Likes/Likes.types'

export const getLastLikesKey = (commentId: number): string =>
  `${COMMENT_API_PREFIX}/${commentId}/last_likes`

interface UseLastLikesOptions {
  commentId: number
  config?: SWRConfiguration<LikeInterface[], Error>
}

export const useLastLikes = ({ commentId, config }: UseLastLikesOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, LikeInterface[]>({
    method: RequestMethodsEnum.get,
  })

  return useSWR<LikeInterface[], Error>(getLastLikesKey(commentId), fetcher, config)
}
