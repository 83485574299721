import { useTranslation } from 'next-i18next'
import { ConditonalWrapper } from 'shared/components/conditional-wrapper'
import { formatTimestampToReadableDate } from 'shared/utils/date-time/format-timestamp-to-readable-date'
import { getShortTimeLabel } from 'shared/utils/date-time/get-short-time-label'
import { UserInfoPopover } from 'modules/community/components/user-info-popover/user-info-popover'

interface CommentHeaderProps {
  userName: string
  userId: number
  isCommunity?: boolean
  commentTimestamp: number
  wasEdited: boolean
}

export const CommentHeader = ({
  userName,
  userId,
  isCommunity = false,
  commentTimestamp,
  wasEdited,
}: CommentHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div className="mr-8 flex flex-col items-start">
      <ConditonalWrapper
        when={isCommunity}
        wrapper={children => <UserInfoPopover userId={userId}>{children}</UserInfoPopover>}
      >
        <span
          className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue sm:max-w-sm lg:max-w-lg"
          title={userName}
        >
          {userName}
        </span>
      </ConditonalWrapper>

      <div
        className="rtl mt-2 text-[15px] leading-5 text-lightgray-100"
        title={formatTimestampToReadableDate(commentTimestamp)}
      >
        {getShortTimeLabel(new Date(commentTimestamp), t)}
        {wasEdited && ` • (${t('comments.state.edited')})`}
      </div>
    </div>
  )
}
