import { CommentInterface } from 'modules/comments/components/comment/comment.types'

export const mergeComments = (
  localComments: CommentInterface[],
  serverComments: CommentInterface[],
  isCommunityComments: boolean,
): CommentInterface[] => {
  const merged = [...localComments]

  serverComments.forEach(serverComment => {
    const index = merged.findIndex(c => c.id === serverComment.id)
    if (index !== -1) {
      // If the comment is found, replace it with the server comment
      merged[index] = serverComment
    } else {
      // If the comment is not found, add it
      merged.push(serverComment)
    }
  })

  if (isCommunityComments) {
    merged.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  } else {
    merged.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }

  return merged
}
