import useSWR, { SWRConfiguration } from 'swr'
import { COMMENT_API_PREFIX } from 'shared/api/constants/api-constants'
import { PAGINATION_ORDER } from 'shared/constants/api'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { ApiPaginationResponseInterface } from 'shared/types/api-pagination-response-interface'
import { CommentInterface } from '../components/comment/comment.types'
import { CommentsTypeEnum } from '../types'
import { commentSourceKeyMap } from '../utils/constants'

const PAGE_COMMENTS_DEFAULT_PAGINATION_LIMIT = 10

// TODO: temporary solution for adapting to new contracts from the backend
// Epic - https://app.shortcut.com/systeme/epic/30633
type PageCommentsResponse = ApiPaginationResponseInterface<CommentInterface> | CommentInterface[]

export const getPageCommentsKey = (
  parentId: number | null,
  commentsType: CommentsTypeEnum,
  pageId: number,
  lastCommentId: number,
  sortOrder: (typeof PAGINATION_ORDER)[keyof typeof PAGINATION_ORDER] = PAGINATION_ORDER.ASC,
): string => {
  const commentSourceKey = commentSourceKeyMap[commentsType]

  const searchParams = new URLSearchParams()
  searchParams.set('pagination[limit]', PAGE_COMMENTS_DEFAULT_PAGINATION_LIMIT.toString())
  searchParams.set('pagination[order]', sortOrder)
  searchParams.set('parent', parentId?.toString() ?? 'null')
  searchParams.set(commentSourceKey, pageId.toString())

  if (lastCommentId === 0) {
    return `/api/comment/comments?${searchParams.toString()}`
  }

  searchParams.set('pagination[startFrom]', lastCommentId.toString())
  return `${COMMENT_API_PREFIX}?${searchParams.toString()}`
}

interface UsePageCommentsOptions {
  parentId: number | null
  commentsType: CommentsTypeEnum
  pageId: number
  lastCommentId: number
  sortOrder: (typeof PAGINATION_ORDER)[keyof typeof PAGINATION_ORDER]
  config?: SWRConfiguration<PageCommentsResponse, Error>
}

export const usePageComments = ({
  parentId,
  commentsType,
  pageId,
  lastCommentId,
  sortOrder,
  config,
}: UsePageCommentsOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    PageCommentsResponse
  >({
    method: RequestMethodsEnum.get,
  })

  return useSWR<PageCommentsResponse>(
    getPageCommentsKey(parentId, commentsType, pageId, lastCommentId, sortOrder),
    fetcher,
    config,
  )
}
