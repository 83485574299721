import { twJoin, twMerge } from 'tailwind-merge'
import { AudioLoaderFile } from 'shared/components/audio-loader-file'
import { Loader } from 'shared/components/loader'
import PlayIcon from 'shared/icons/play-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import FullscreenGalleryButton from 'modules/attachments/components/preview/fullscreen-gallery-button'
import { playableAttachmentTypes } from 'modules/community/const/attachment-consts'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
  PostAttachmentType,
} from 'modules/community/types/post-attachment'
import { previewFactory } from './preview-factory'
import RemoveAttachmentButton from './remove-button'

interface AttachmentPreviewItemProps {
  className?: string
  previewClassName?: string
  size?: 'small' | 'original'
  attachment: PostAttachmentType
}

export default function AttachmentPreviewItem({
  className,
  previewClassName,
  size = 'small',
  attachment,
}: AttachmentPreviewItemProps) {
  const { isEditable } = usePostAttachmentsContext()
  const isLoading = attachment.status === AttachmentUploadStatusEnum.Uploading
  const isFailed = attachment.status === AttachmentUploadStatusEnum.Failed
  const isPlayable = playableAttachmentTypes.includes(attachment.type) && !isLoading && !isFailed

  return (
    <div
      className={twMerge(
        'group relative aspect-square h-24 w-24 overflow-hidden md:h-32 md:w-32',
        isLoading && 'cursor-wait',
        className,
      )}
    >
      {previewFactory(attachment, previewClassName, size)}
      <div
        aria-hidden
        className={twJoin(
          'absolute left-0 top-0 z-10 h-full w-full rounded-lg bg-black opacity-0 transition-all',
          !isLoading && 'md:group-hover:opacity-75',
        )}
      />
      {isLoading && attachment.type !== AttachmentTypeEnum.Audio && (
        <Loader
          iconClassName="h-16 w-16"
          className="absolute left-[50%] top-[50%] h-16 w-16 -translate-x-[50%] -translate-y-[50%]"
        />
      )}
      {isLoading && attachment.type === AttachmentTypeEnum.Audio && (
        <AudioLoaderFile
          iconClassName="h-16 w-16"
          className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] md:h-8 md:w-8 lg:h-16 lg:w-16"
        />
      )}

      {isPlayable && (
        <PlayIcon className="absolute left-1/2 top-1/2 z-10 w-10 -translate-x-1/2 -translate-y-1/2 transform fill-black" />
      )}
      {!isLoading && (
        <>
          {!isFailed && attachment.type !== AttachmentTypeEnum.Audio && (
            <FullscreenGalleryButton
              id={attachment.id}
              className={twJoin(
                'absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 transform opacity-0 group-hover:opacity-100',
                isEditable && 'hidden md:block',
              )}
            />
          )}
          {isEditable && (
            <RemoveAttachmentButton
              id={attachment.id}
              className="absolute right-2 top-2 z-20 opacity-100 transition-all group-hover:top-2 group-hover:opacity-100 md:-top-2 md:opacity-0"
            />
          )}
        </>
      )}
    </div>
  )
}
