import React from 'react'
import { useSlate } from 'slate-react'
import { twMerge } from 'tailwind-merge'
import MentionToolbarIcon from 'shared/icons/mention-toolbar-icon'
import { ToolbarButton } from '../../common/toolbar-button'
import { isMentionActive } from '../../utils/mention'

const MentionButton = ({ onClick }: { onClick: () => void }) => {
  const editor = useSlate()
  const mentionActive = isMentionActive(editor)

  return (
    <ToolbarButton active={mentionActive} format="mention" onClick={onClick}>
      <MentionToolbarIcon className="size-5 text-[#8995b0b3] hover:text-[initial] md:size-6" />
    </ToolbarButton>
  )
}

export default MentionButton
