import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

export interface LoaderProps {
  small?: boolean
  type?: 'light' | 'dark'
  className?: string
  style?: CSSProperties
  iconClassName?: string
}

export const AudioPreviewLoader = ({
  small = false,
  type = 'light',
  className,
  style,
  iconClassName,
}: LoaderProps) => (
  <div role="status" className={className} style={style}>
    <svg
      aria-hidden="true"
      className={twMerge(
        `${small ? 'h-5 w-5' : 'h-8 w-8'}  ${className} ${
          type === 'light' ? 'fill-blue text-white' : 'fill-white text-darkblue' 
        }`,
        
        iconClassName,
      )}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
    >
      <path d="M44.7562 10.7663C44.7562 9.68413 43.8789 8.80688 42.7968 8.80688C41.7146 8.80688 40.8374 9.68413 40.8374 10.7663V55.2338C40.8374 56.3159 41.7146 57.1931 42.7968 57.1931C43.8789 57.1931 44.7562 56.3159 44.7562 55.2338V10.7663Z" />
      <path d="M13.4062 14.6851C14.4883 14.6851 15.3655 15.5623 15.3655 16.6444V49.3557C15.3655 50.4378 14.4883 51.3151 13.4062 51.3151C12.324 51.3151 11.4468 50.4378 11.4468 49.3557V16.6444C11.4468 15.5623 12.324 14.6851 13.4062 14.6851Z" />
      <path d="M3.60928 24.4819C4.6914 24.4819 5.56865 25.3592 5.56865 26.4413V39.5588C5.56865 40.641 4.6914 41.5182 3.60928 41.5182C2.52714 41.5182 1.6499 40.641 1.6499 39.5588V26.4413C1.6499 25.3592 2.52714 24.4819 3.60928 24.4819Z" />
      <path d="M25.1624 24.4818C25.1624 23.3997 24.2852 22.5225 23.203 22.5225C22.1209 22.5225 21.2437 23.3997 21.2437 24.4818V41.5181C21.2437 42.6003 22.1209 43.4775 23.203 43.4775C24.2852 43.4775 25.1624 42.6003 25.1624 41.5181V24.4818Z" />
      <path d="M32.9999 18.6038C34.0821 18.6038 34.9593 19.481 34.9593 20.5631V45.4369C34.9593 46.519 34.0821 47.3963 32.9999 47.3963C31.9177 47.3963 31.0405 46.519 31.0405 45.4369V20.5631C31.0405 19.481 31.9177 18.6038 32.9999 18.6038Z" />
      <path d="M52.5937 18.6038C53.6758 18.6038 54.553 19.481 54.553 20.5631V45.4369C54.553 46.519 53.6758 47.3963 52.5937 47.3963C51.5115 47.3963 50.6343 46.519 50.6343 45.4369V20.5631C50.6343 19.481 51.5115 18.6038 52.5937 18.6038Z" />
      <path d="M64.3499 22.5225C64.3499 21.4404 63.4727 20.5631 62.3905 20.5631C61.3084 20.5631 60.4312 21.4404 60.4312 22.5225V43.4775C60.4312 44.5596 61.3084 45.4369 62.3905 45.4369C63.4727 45.4369 64.3499 44.5596 64.3499 43.4775V22.5225Z" />
    </svg>
  </div>
)
