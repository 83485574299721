import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import { CommentEditor } from '../comment-editor'
import { MessageBoxProps } from './message-box.types'
import { useCommentBox } from './use-comment-box'

export const MessageBox = ({
  parentId,
  setActiveComment = () => {},
  setResponses = () => {},
  setOpenedResponses = () => {},
  onError,
  level = 0,
  replyInfo,
}: MessageBoxProps) => {
  const { handleAddComment, attachmentsRef, attachments, setAttachments, initialValue } =
    useCommentBox({
      parentId,
      level,
      setActiveComment,
      setResponses,
      setOpenedResponses,
      onError,
      replyInfo,
    })

  return (
    <PostAttachmentsProvider ref={attachmentsRef} isEditable onChange={setAttachments}>
      <CommentEditor
        handleAddComment={handleAddComment}
        setActiveComment={setActiveComment}
        isRoot={level === 0}
        initialValue={initialValue}
      />
    </PostAttachmentsProvider>
  )
}
