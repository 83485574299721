import DOMPurify from 'isomorphic-dompurify'
import { Descendant, Editor, Element as SlateElement, Text, Transforms } from 'slate'
import { jsx } from 'slate-hyperscript'
import { MentionInterface } from 'modules/community/components/editor/elements/mention/mention.types'
import {
  CustomElement,
  CustomText,
  ImageElement,
  LinkElement,
  MentionElement,
} from 'modules/community/types/CustomEditor'
import { isBlockActive } from 'modules/community/utils/editor/core'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const insertText = (editor: Editor, nativeEmoji: string) => {
  const textHtml = serializeHTML(editor)

  const text = { text: nativeEmoji }

  Transforms.insertNodes(editor, text)
}

export const insertImage = (editor: Editor, url: string) => {
  const image: ImageElement = { type: 'image', url, children: [{ text: '' }] }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  })
}

export const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor)
  //@ts-ignore
  return marks ? marks[format] === true : false
}

export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  })
  let newProperties: Partial<CustomElement>

  newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : (format as CustomElement['type']),
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] } as CustomElement
    Transforms.wrapNodes(editor, block)
  }
}

export const serializeHTML = (node: { children: Descendant[] } | Descendant) => {
  if (Text.isText(node)) {
    let string = DOMPurify.sanitize(node.text)

    const textNode = node
    if (textNode.underline) {
      string = string.trim() ? `<u>${string}</u>` : ''
    }
    if (textNode.italic) {
      string = string.trim() ? `<em>${string}</em>` : ''
    }
    if (textNode.bold) {
      string = string.trim() ? `<strong>${string}</strong>` : ''
    }
    return string
  }

  const children: string = node.children.map((n: Descendant) => serializeHTML(n)).join('')

  const elementNode = node as CustomElement
  switch (elementNode.type) {
    case 'paragraph':
      return children.trim() ? `<p>${children}</p>` : '<br>'
    case 'link': {
      const linkNode = node as LinkElement
      return children.trim()
        ? `<a href="${linkNode.url}" class="text-decoration-line: underline text-blue" target="_blank">${children}</a>`
        : ''
    }
    case 'image': {
      const imageNode = node as ImageElement
      return `<img src="${imageNode.url}">${children}</img>`
    }
    case 'bulleted-list': {
      return children.trim() ? `<ul class="list-disc pl-5">${children}</ul>` : ''
    }
    case 'numbered-list': {
      return children.trim() ? `<ol class="list-decimal pl-5">${children}</ol>` : ''
    }
    case 'list-item': {
      return children.trim() ? `<li>${children}</li>` : ''
    }
    case 'mention': {
      const mentionNode = node as MentionElement
      return mentionNode.displayName.trim() ? `<@${mentionNode.id}:${mentionNode.displayName}>` : ''
    }
    default:
      return children
  }
}

export const deserializeTextHtml = (text: string, mentions: MentionInterface[]) => {
  const html = new DOMParser().parseFromString(text, 'text/html')
  return deserializeHTML(html.body as HTMLElement, mentions)
}

export function deserializeHTML(
  el: HTMLElement,
  mentions: MentionInterface[],
  markAttributes: { bold?: boolean; italic?: boolean; underline?: boolean } = {},
): CustomElement | CustomText | null | Descendant[] {
  if (el.nodeType === Node.TEXT_NODE) {
    const textContent = el.textContent || ''
    const regex = /<@(\d+):([^>]+)>/g
    let lastIndex = 0
    const children: Descendant[] = []
    let match

    while ((match = regex.exec(textContent)) !== null) {
      // Text before the mention
      if (match.index > lastIndex) {
        const textBefore = textContent.substring(lastIndex, match.index)
        children.push(jsx('text', markAttributes, textBefore))
      }
      // The mention
      const userId = Number(match[1])
      const displayName = match[2]
      const mentionData = mentions?.find(m => m.userId === userId && m.displayName === displayName)
      if (mentionData) {
        children.push(
          jsx(
            'element',
            {
              type: 'mention',
              id: mentionData.userId,
              displayName: mentionData.displayName,
              profileImageUrl: mentionData.profileImage,
            },
            [{ text: '' }],
          ),
        )
      } else {
        // If mention data not found, treat as plain text
        children.push(jsx('text', markAttributes, match[0]))
      }
      lastIndex = regex.lastIndex
    }
    // Text after the last mention
    if (lastIndex < textContent.length) {
      const textAfter = textContent.substring(lastIndex)
      children.push(jsx('text', markAttributes, textAfter))
    }

    return children
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true
      break
    case 'EM':
      nodeAttributes.italic = true
      break
    case 'U':
      nodeAttributes.underline = true
      break
  }

  const children = Array.from(el.childNodes)
    .map(node => deserializeHTML(node as HTMLElement, mentions, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'BR':
      return jsx('element', { type: 'paragraph' }, children)
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children)
    case 'IMG':
      return jsx('element', { type: 'image', url: el.getAttribute('src') }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    default: {
      return children as CustomElement | CustomText | Descendant[] | null
    }
  }
}
