import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { Suggestion } from 'modules/comments/components/comment/comment.types'

export const getSuggestionsListKey = (
  communityPath: string,
  postId?: number,
  displayNameSearchBy?: string,
): string => {
  const params = new URLSearchParams()

  if (displayNameSearchBy) {
    params.append('displayNameSearchBy', displayNameSearchBy)
  }

  if (postId) {
    params.append('postId', postId.toString())
  }

  return `${COMMUNITY_API_PREFIX}/${communityPath}/get-members-for-mention?${params.toString()}`
}

interface UseSuggestionsListOptions {
  communityPath?: string
  postId?: number
  displayNameSearchBy?: string
  config?: SWRConfiguration<Suggestion[], Error>
}

export const useSuggestionsList = ({
  communityPath,
  postId,
  displayNameSearchBy,
  config,
}: UseSuggestionsListOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, Suggestion[]>({
    method: RequestMethodsEnum.get,
  })

  return useSWR<Suggestion[], Error>(
    () => {
      if (communityPath) {
        return getSuggestionsListKey(communityPath, postId, displayNameSearchBy)
      }
      return null
    },
    fetcher,
    config,
  )
}
