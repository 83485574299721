import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { COMMENT_API_PREFIX } from 'shared/api/constants/api-constants'
import { adaptFetcherToSWRMutationFn } from 'shared/api/utils/adapt-fetcher-to-swr-mutation'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { UpdateCommentRequestType } from 'modules/comments/api/types'
import { CommentInterface } from 'modules/comments/components/comment/comment.types'

interface UseUpdateCommentOptions {
  commentId: number
  config?: SWRMutationConfiguration<CommentInterface, Error, string, UpdateCommentRequestType>
}

export const useUpdateComment = ({ commentId, config }: UseUpdateCommentOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.put, CommentInterface, UpdateCommentRequestType>({
    method: RequestMethodsEnum.put,
  })

  return useSWRMutation(
    `${COMMENT_API_PREFIX}/${commentId}`,
    adaptFetcherToSWRMutationFn(fetcher),
    config,
  )
}
