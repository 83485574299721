import { ReactEditor } from 'slate-react'
import { Editor, Range, Element as SlateElement, Transforms } from 'slate'
import { CustomEditor, LinkElement } from 'modules/community/types/CustomEditor'
import { isBlockActive } from './core'

export const insertLink = (editor: Editor, url: string) => {
  if (!editor.selection) {
    const endOfDoc = Editor.end(editor, [])
    if (endOfDoc) {
      Transforms.select(editor, endOfDoc)
    }
  }
  wrapLink(editor, url)
  ReactEditor.focus(editor)
}

export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

export const wrapLink = (editor: CustomEditor, url: string) => {
  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
    Transforms.move(editor, { unit: 'offset' })
    Transforms.insertNodes(editor, { text: ' ' })
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
