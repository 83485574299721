import { useMemo, useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { CommentInterface } from 'modules/comments/components/comment/comment.types'

export interface HighlightContextType {
  highlightedComment: CommentInterface | null
  setHighlightedComment: (value: CommentInterface | null) => void
}

export const CommentHighlightContext = createContext<HighlightContextType | undefined>(undefined)

export const useCommentHighlight = () => {
  const context = useContext(CommentHighlightContext)
  if (!context) {
    throw new Error('useCommentHighlight must be used within a CommentHighlightProvider')
  }
  return context
}

export const CommentHighlightProvider = ({ children }: { children: React.ReactNode }) => {
  const [highlightedComment, setHighlightedComment] = useState<CommentInterface | null>(null)

  const value = useMemo(
    () => ({ highlightedComment, setHighlightedComment }),
    [highlightedComment, setHighlightedComment],
  )

  return (
    <CommentHighlightContext.Provider value={value}>{children}</CommentHighlightContext.Provider>
  )
}
