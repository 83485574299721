import { useEffect } from 'react'
import { useCommentHighlight } from 'modules/comments/contexts/comment-highlight'

export function useHighlighting(
  commentId: number,
  ref: React.RefObject<HTMLElement>,
  duration = 2000,
): boolean {
  const { highlightedComment, setHighlightedComment } = useCommentHighlight()
  const isHighlighted = highlightedComment?.id === commentId

  useEffect(() => {
    if (isHighlighted && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      const timer = setTimeout(() => {
        setHighlightedComment(null)
      }, duration)
      return () => clearTimeout(timer)
    }
  }, [isHighlighted, ref, setHighlightedComment, duration])

  return isHighlighted
}
