import { Float } from '@headlessui-float/react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { NeutralButton } from 'shared/components/button'
import ButtonLinkWrapper from 'shared/components/button/components/button-link-wrapper'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ActivityIcon from 'shared/icons/activity-icon'
import CalendarIcon from 'shared/icons/calendar-icon'
import MailIcon from 'shared/icons/mail-icon'
import { getTimeDifference } from 'shared/utils/date-time/get-time-difference'
import { timeSince } from 'shared/utils/date-time/time-since'
import { getContactUrl } from 'shared/utils/url-utils'
import { UserInfoPopoverSkeleton } from 'modules/community/components/user-info-popover/user-info-popover-skeleton'
import useUserInfo from 'modules/community/hooks/use-user-info'
import { dateFormat } from 'modules/profile/utils/date-time-format'
import { MAX_VISIBLE_BIO_CAHRS } from './constans/max-visible-bio-chars'
import { getTruncatedText } from './utils/get-truncated-text'

interface UserInfoPopoverProps {
  userId: number
  className?: string
}

export const UserInfoPopover = ({
  children,
  userId,
  className,
}: PropsWithChildren<UserInfoPopoverProps>) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { show, delayClose, delayOpen, close, open } = useFloatHover({ openDelay: 600 })
  const { data: userInfo } = useUserInfo({ userId, shouldFetch: show })
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const isActive = userInfo?.lastActiveAt
    ? getTimeDifference(Date.now(), userInfo.lastActiveAt, 'minute') <= 5
    : false

  return (
    <Popover
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
      }}
      className={twMerge('overflow-hidden text-ellipsis whitespace-nowrap', className)}
    >
      <Float
        show={show}
        offset={10}
        portal
        shift
        autoPlacement
        arrow
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'flex h-full items-center'}
      >
        <PopoverButton
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          onClick={show ? close : open}
          className="flex h-fit w-full items-center justify-around gap-2.5 focus:outline-none"
        >
          {children}
        </PopoverButton>
        <PopoverPanel
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          className="flex flex-col rounded-lg bg-white text-sm shadow-lg"
        >
          {!!userInfo ? (
            <div className="flex h-full w-full flex-col items-start gap-3 px-3 py-5 sm:min-h-[250px] sm:w-[500px] sm:flex-row">
              <img
                src={userInfo.profileImageUrl}
                alt={userInfo.displayName}
                className="h-40 w-40 rounded-full"
                width={160}
                height={160}
              />
              <div className="flex w-full flex-grow flex-col gap-3">
                <div className="max-w-[304px] overflow-hidden text-ellipsis break-words">
                  <h3 className="line-clamp-2 max-w-full text-3xl">{userInfo.displayName}</h3>
                </div>
                {isActive ? (
                  <div className="text-avertaPE inline-flex items-center gap-2">
                    <div className="h-[12px] w-[12px] rounded-full bg-green" />
                    <span>{t('community.user_info.active_now')}</span>
                  </div>
                ) : (
                  !!userInfo.lastActiveAt && (
                    <div className="text-avertaPE inline-flex items-center gap-2">
                      <ActivityIcon />
                      <span className="text-base font-normal text-lightgray-500">
                        {t('community.user_info.last_activity_at', {
                          date: timeSince(new Date(userInfo.lastActiveAt), {
                            locale: user?.dashboardLocale,
                          }),
                        })}
                      </span>
                    </div>
                  )
                )}
                {!!userInfo?.createdAt && isAuthorizedUser(user) && (
                  <div className="text-avertaPE inline-flex items-center gap-2">
                    <CalendarIcon />
                    <span className="text-base font-normal text-lightgray-500">
                      {t('members.list.member.joined')}{' '}
                      {dateFormat(userInfo.createdAt, {
                        dateFormat: user?.dateFormat,
                      })}
                    </span>
                  </div>
                )}
                {!!userInfo?.email && (
                  <div className="text-avertaPE inline-flex items-center gap-2">
                    <MailIcon />
                    <a
                      href={`mailto:${userInfo.email}`}
                      className="text-base font-normal text-lightgray-500"
                      title={userInfo.email}
                    >
                      {userInfo.email}
                    </a>
                  </div>
                )}
                {!!userInfo?.bio && (
                  <div className="flex flex-col items-start">
                    <span className="text-avertaPE max-w-[304px] whitespace-normal break-words text-base font-normal leading-6 text-lightgray-100">
                      {getTruncatedText(userInfo.bio, isExpanded, MAX_VISIBLE_BIO_CAHRS)}
                      {userInfo.bio.length > MAX_VISIBLE_BIO_CAHRS && (
                        <button
                          className="text-base font-normal text-blue"
                          onClick={() => setIsExpanded(!isExpanded)}
                        >
                          ...
                          {isExpanded
                            ? t('community.user_info.hide.button')
                            : t('community.user_info.readmore.button')}
                        </button>
                      )}
                    </span>
                  </div>
                )}
                {userInfo?.contactId && (
                  <ButtonLinkWrapper
                    href={getContactUrl(userInfo.contactId)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <NeutralButton
                      className="w-full"
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                      }}
                    >
                      {t('community.user_info.open_contact_page')}
                    </NeutralButton>
                  </ButtonLinkWrapper>
                )}
              </div>
            </div>
          ) : (
            <UserInfoPopoverSkeleton />
          )}
        </PopoverPanel>
      </Float>
    </Popover>
  )
}
