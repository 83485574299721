import { useRef, useState } from 'react'
import { Descendant } from 'slate'
import { mutate } from 'swr'
import { AttachmentsRefInterface } from 'modules/attachments/components/context'
import { useAddComment } from 'modules/comments/api/add-comment'
import { CommentInterface } from 'modules/comments/components/comment/comment.types'
import { useCommentsContext } from 'modules/comments/components/comments-list'
import { useCommentHighlight } from 'modules/comments/contexts/comment-highlight'
import { OnErrorType } from 'modules/comments/types'
import { isCommunityExtra } from 'modules/comments/utils/is-community-extra'
import { getCommentsCountKey } from 'modules/community/api/get-comments-count'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { escapeAttachmentLocalFields } from 'modules/community/utils/attachments'

const updateResponsesCount = (commentList: CommentInterface[], parentId: number) =>
  commentList.map(comment =>
    comment.id === parentId ? { ...comment, totalReplies: comment.totalReplies + 1 } : comment,
  )

export function useCommentBox({
  parentId,
  level = 0,
  setActiveComment = () => {},
  setResponses = () => {},
  setOpenedResponses = () => {},
  onError,
  replyInfo,
}: {
  parentId: number | null
  level?: number
  setActiveComment?: (comment: CommentInterface | null) => void
  setResponses?: (parentId: number, replies: CommentInterface) => void
  setOpenedResponses?: (value: boolean) => void
  onError?: OnErrorType
  replyInfo?: { userId: number; displayName: string; profileImage?: string }
}) {
  const { setComments, commentsList, extra } = useCommentsContext()
  const { setHighlightedComment } = useCommentHighlight()
  const parentIRI = parentId ? `/api/comment/comments/${parentId}` : null
  const attachmentsRef = useRef<AttachmentsRefInterface>(null)
  const [attachments, setAttachments] = useState<PostAttachmentType[]>([])
  const addCommentMutation = useAddComment({})

  const isCommunityComments = isCommunityExtra(extra)

  const initialValue: Descendant[] | undefined = replyInfo
    ? [
        {
          type: 'paragraph',
          children: [
            {
              type: 'mention',
              displayName: replyInfo.displayName,
              id: replyInfo.userId,
              profileImageUrl: replyInfo.profileImage,
              children: [{ text: '' }],
            },
            {
              text: ', ',
            },
          ],
        },
      ]
    : undefined

  async function handleAddComment(textHtml: string) {
    try {
      const newComment = {
        text: textHtml,
        parent: parentIRI,
        ...(isCommunityComments
          ? {
              commentSourceCommunityPost: {
                communityPostId: extra.community.postId,
              },
            }
          : {
              commentSourcePage: {
                pageId: extra.course.pageId,
              },
            }),
        attachments: escapeAttachmentLocalFields(attachments),
      }
      const data = await addCommentMutation.trigger(newComment)
      const newCommentWithFlag: CommentInterface = { ...data, __isNew: true }
      if (parentId === null) {
        setComments(
          isCommunityComments
            ? [...commentsList, newCommentWithFlag]
            : [newCommentWithFlag, ...commentsList],
        )
        isCommunityComments && setHighlightedComment(newCommentWithFlag)
      } else {
        const updatedCommentList = updateResponsesCount(commentsList, parentId)
        setComments(updatedCommentList)
        if (level === 0) {
          setOpenedResponses(true)
        }

        setResponses(parentId, newCommentWithFlag)
        isCommunityComments && setHighlightedComment(newCommentWithFlag)
      }
      setActiveComment(null)
      isCommunityComments &&
        mutate(getCommentsCountKey(extra.community.communityPath, [extra.community.postId]))
      attachmentsRef.current?.clear()
    } catch (e) {
      onError && onError(e)
    }
  }

  return {
    handleAddComment,
    attachmentsRef,
    attachments,
    setAttachments,
    initialValue,
  }
}
