/**
 * Adapts a custom fetcher `(url, data?)` to the signature `(url, { arg }) => Promise<TResponse>`
 * required by `useSWRMutation`.
 *
 * **Why?**
 * - `useSWRMutation` invokes the `mutationFn` as `(key, { arg }) => Promise<T>`.
 * - If your fetcher expects `(url, data)`, you need this adapter to forward `arg` properly.
 *
 * **Usage Example:**
 * ```ts
 * const { fetcher } = useApiWrapperWithErrorValidation({ method: 'post' });
 * const mutationFn = adaptFetcherToSWRMutationFn(fetcher);
 *
 * const { trigger } = useSWRMutation('/api/endpoint', mutationFn);
 * await trigger({ foo: 'bar' });
 * ```
 *
 * @template TResponse - The response type from the fetcher.
 * @template TArg - The argument type passed to `trigger(arg)`.
 * @param fetcher A function `(url, data?) => Promise<TResponse>`.
 * @returns A function `(url, { arg }) => Promise<TResponse>` suitable for `useSWRMutation`.
 * @see https://swr.vercel.app/docs/mutation#useswrmutation-parameters
 */
export const adaptFetcherToSWRMutationFn =
  <TResponse, TArg = unknown>(fetcher: (url: string, data?: TArg) => Promise<TResponse>) =>
  async (url: string, { arg }: { arg: TArg }) =>
    fetcher(url, arg)
