import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { ComponentProps, Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import CloseIcon from 'shared/icons/close-icon'
import PenOutlineIcon from 'shared/icons/pen-outline-icon'
import ThreeDotsNavIcon from 'shared/icons/three-dots-nav-icon'
import TrashOutlineIcon from 'shared/icons/trash-outline-icon'
import { isCommunityExtra } from 'modules/comments/utils/is-community-extra'
import { CommentInterface } from '../comment/comment.types'
import { useCommentsContext } from '../comments-list'
import ActionsMenuItem from './actions-menu-items'

type CommentActionsProps = {
  onDelete: () => void
  onDeleteAndBanUser: () => void
  onEdit: () => void
  className?: string
  commentData: CommentInterface
}

const MenuAnchorArrow = (props: ComponentProps<'svg'>) => (
  <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="white"
      d="M7.26795 1C8.03775 -0.333333 9.96225 -0.333333 10.7321 1L17.6603 13C18.4301 14.3333 17.4678 16 15.9282 16H2.0718C0.532197 16 -0.430054 14.3333 0.339746 13L7.26795 1Z"
    />
  </svg>
)

export default function CommentActions({
  onDelete,
  onDeleteAndBanUser,
  onEdit,
  className,
  commentData,
}: CommentActionsProps) {
  const { t } = useTranslation()
  const { extra } = useCommentsContext()

  const { canDeleteComment, canDeleteCommentAndBanAuthor, canUpdateComment } = commentData

  const isCommunityComments = isCommunityExtra(extra)
  const shouldRenderMenu = canDeleteComment || canUpdateComment || canDeleteCommentAndBanAuthor

  if (!shouldRenderMenu) return null

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton className={className}>
            <div className="relative">
              <ThreeDotsNavIcon className="h-8 w-8 rounded-full text-bluegray transition-colors duration-300 hover:bg-[#E6E6E6]" />
            </div>
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              as="ul"
              anchor={{ to: 'bottom end', gap: 10 }}
              className="z-10 flex w-max min-w-[144px] max-w-sm translate-x-1 flex-col rounded-[10px] bg-white py-3 shadow-[0px_4px_10px_0px_#00000040]"
            >
              {canDeleteComment && (
                <ActionsMenuItem onClick={onDelete}>
                  <CloseIcon className="h-5 w-5 fill-darkblue group-hover:fill-darkblue/70" />
                  {t('comments.actions.remove')}
                </ActionsMenuItem>
              )}
              {canUpdateComment && (
                <ActionsMenuItem onClick={onEdit}>
                  <PenOutlineIcon className="h-5 w-5" />
                  {t('comments.actions.edit')}
                </ActionsMenuItem>
              )}
              {canDeleteCommentAndBanAuthor && isCommunityComments && (
                <ActionsMenuItem onClick={onDeleteAndBanUser}>
                  <TrashOutlineIcon className="h-5 w-5" />
                  {t('comments.actions.remove_and_ban_user')}
                </ActionsMenuItem>
              )}
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  )
}
