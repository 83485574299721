import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { mutate } from 'swr'
import { twJoin } from 'tailwind-merge'
import Confirmation from 'shared/components/confirmation-modal'
import EllipseIcon from 'shared/icons/ellipse-icon'
import {
  AttachmentsRefInterface,
  PostAttachmentsProvider,
} from 'modules/attachments/components/context'
import AttachmentsPreview from 'modules/attachments/components/preview'
import { useDeleteComment } from 'modules/comments/api/delete-comment'
import { useLikeComment } from 'modules/comments/api/like-comment'
import { useUnlikeComment } from 'modules/comments/api/unlike-comment'
import { useUpdateComment } from 'modules/comments/api/update-comment'
import { useHighlighting } from 'modules/comments/hooks/use-highlighting'
import { EditorMode } from 'modules/comments/types'
import { LoadMoreButton } from 'modules/comments/ui/load-more-button'
import { findLastComment } from 'modules/comments/utils/find-last-comment'
import { isCommunityExtra } from 'modules/comments/utils/is-community-extra'
import { getCommentsCountKey } from 'modules/community/api/get-comments-count'
import * as postsApi from 'modules/community/api/posts-api'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { BanPeriodUnitEnum } from 'modules/community/enums/member-enum'
import { deserializeTextHtml } from 'modules/community/hooks/slate-editor-utils'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { escapeAttachmentLocalFields } from 'modules/community/utils/attachments'
import { RemoveRecentActivitiesCheckbox } from 'modules/post/remove-recent-activities-checkbox'
import { Likes } from '../Likes'
import CommentActions from '../comment-actions'
import { CommentEditor } from '../comment-editor'
import { TextWithMentions } from '../comment-editor/deserealizeMentionToHTML'
import { useCommentsContext } from '../comments-list'
import { MessageBox } from '../message-box/message-box'
import { CommentInterface, CommentProps } from './comment.types'
import { CommentHeader } from './ui/comment-header'

const LINE_HEIGHT_OFFSET = 25

export const Comment = ({
  data,
  level = 0,
  activeComment,
  setActiveComment,
  onError,
  replies,
  loadMoreReplies,
}: CommentProps) => {
  const { t } = useTranslation()
  const { setComments, commentsList, repliesList, updateReplies, readOnly, extra } =
    useCommentsContext()

  const [isRepliesOpen, setIsRepliesOpen] = useState(replies?.length || false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteAndBanUserOpened, setIsDeleteAndBanUserOpened] = useState(false)
  const [removeRecentActivitiesChecked, setRemoveRecentActivitiesChecked] = useState(false)
  const [attachments, setAttachments] = useState<PostAttachmentType[]>([])

  const updateCommentMutation = useUpdateComment({ commentId: data.id })
  const deleteCommentMutation = useDeleteComment({ commentId: data.id })
  const likeCommentMutation = useLikeComment({ commentId: data.id })
  const unlikeCommentMutation = useUnlikeComment({ commentId: data.id })

  const attachmentsRef = useRef<AttachmentsRefInterface>(null)
  const lineRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const commentRef = useRef<HTMLDivElement | null>(null)

  const isHighlighted = useHighlighting(data.id, commentRef, 2000)
  const isCommentOwner = data.canDeleteComment
  const commentTimestamp = new Date(data.updatedAt ?? data.createdAt).getTime()
  const wasEdited = data.updatedAt !== data.createdAt
  const isCommunityComments = isCommunityExtra(extra)
  const isLoadingReplies = repliesList[data.id]?.isLoading ?? false
  const isEndReplies = repliesList[data.id]?.isEnd ?? true

  const calculateLineHeight = useCallback(() => {
    if (containerRef.current && lineRef.current) {
      const container = containerRef.current
      const line = lineRef.current
      const containerHeight = container.scrollHeight

      const lastReplyElement = container.lastElementChild?.lastElementChild as HTMLElement

      if (lastReplyElement) {
        const lastReplyHeight = lastReplyElement.offsetHeight
        const newLineHeight = containerHeight - lastReplyHeight - LINE_HEIGHT_OFFSET
        line.style.height = `${newLineHeight}px`
      }
    }
  }, [])

  const toggleReplies = async () => {
    if (!isRepliesOpen && !replies?.length && !isLoadingReplies) {
      loadMoreReplies?.({ lastCommentId: 0, parentId: data.id })
    }
    setIsRepliesOpen(prev => !prev)
  }

  const handleReply = async () => {
    commentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    setActiveComment(null)
    // NOTE: It's a workaround to have the comment field re-created due to React life-cycle. Since Slate is uncontrollable and we can only use initValue on mount component
    setTimeout(() => setActiveComment(data), 10)
    if (level === 0) {
      if (!replies?.length && !isLoadingReplies) {
        loadMoreReplies?.({ lastCommentId: 0, parentId: data.id })
      }
      setIsRepliesOpen(true)
    }
  }

  const handleLoadMoreReplies = () => {
    if (!isEndReplies && !isLoadingReplies) {
      const lastComment = findLastComment(repliesList[data.id]?.replies ?? [])
      loadMoreReplies?.({
        lastCommentId: lastComment?.id ?? 0,
        parentId: data.id,
      })
    }
  }

  const handleDeleteComment = async () => {
    const confirmation = confirm(t('comments.components.comments.delete_confirmation_label'))
    if (!confirmation) return

    try {
      await deleteCommentMutation.trigger()
      if (deleteCommentMutation.error) return

      if (level > 0 && !Array.isArray(data.parent)) {
        const parentID = data.parent.id
        updateReplies(parentID, {
          newReplies: repliesList[parentID].replies?.filter(el => el.id !== data.id),
        })
        setComments(
          commentsList.map(comment =>
            comment.id === parentID
              ? { ...comment, totalReplies: comment.totalReplies - 1 }
              : comment,
          ),
        )
      } else {
        setComments(commentsList.filter(el => el.id !== data.id))
      }
      toast.success(t('comments.actions.delete_comment.success'))
      if (isCommunityComments) {
        mutate(getCommentsCountKey(extra.community.communityPath, [extra.community.postId]))
      }
    } catch (e) {
      onError?.(e)
    }
  }

  const handleLikeClick = (comment: CommentInterface) => async () => {
    try {
      const isLiked = comment.isLiked
      const delta = isLiked ? -1 : 1
      const updateFunction = isLiked ? unlikeCommentMutation.trigger : likeCommentMutation.trigger
      await updateFunction()

      const updateLikes = (commentData: CommentInterface) =>
        commentData.id === comment.id
          ? {
              ...commentData,
              likesCount: commentData.likesCount + delta,
              isLiked: !isLiked,
            }
          : commentData

      setComments(commentsList.map(updateLikes))

      if (level > 0 && !Array.isArray(data.parent)) {
        updateReplies(data.parent.id, {
          newReplies: repliesList[data.parent.id]?.replies?.map(r =>
            r.id === comment.id
              ? { ...r, isLiked: !isLiked, likesCount: data.likesCount + delta }
              : r,
          ),
        })
      }
    } catch (e) {
      onError?.(e)
    }
  }

  const handleEditComment = async (value: string, attachments?: PostAttachmentType[]) => {
    try {
      const updatedComment = await updateCommentMutation.trigger({
        text: value,
        attachments: escapeAttachmentLocalFields(attachments),
      })
      setComments(commentsList.map(comment => (comment.id === data.id ? updatedComment : comment)))
      if (level > 0 && !Array.isArray(data.parent)) {
        updateReplies(data.parent.id, {
          newReplies: repliesList[data.parent.id]?.replies?.map(r =>
            r.id === data.id ? updatedComment : r,
          ),
        })
      }

      setIsEditing(false)
    } catch (e) {
      onError?.(e)
    }
  }

  const handleCancelEditing = useCallback(() => setIsEditing(false), [])

  const deleteAndBanUser = async () => {
    if (!isCommunityComments) return
    handleDeleteComment()
    await postsApi.banUser(
      extra.community.communityPath,
      data.userId,
      removeRecentActivitiesChecked
        ? {
            deleteRecentActivityOptions: {
              periodUnit: BanPeriodUnitEnum.day,
              periodDuration: ACTIVITY_CLEANUP_PERIOD_DURATION,
            },
          }
        : undefined,
    )
    toast.success(t('comments.actions.delete_comment_and_ban_user.success'))
  }

  useEffect(() => {
    if (!replies?.length) return
    window.addEventListener('resize', calculateLineHeight)
    return () => window.removeEventListener('resize', calculateLineHeight)
  }, [calculateLineHeight, replies?.length])

  useEffect(() => {
    calculateLineHeight()
  }, [isRepliesOpen, calculateLineHeight, replies, data, isEditing])

  return (
    <PostAttachmentsProvider
      ref={attachmentsRef}
      attachments={data.attachments}
      isEditable={isEditing}
      onChange={setAttachments}
    >
      {isEditing ? (
        <CommentEditor
          initialValue={
            deserializeTextHtml(data.text, data.mentions) as ComponentProps<
              typeof CommentEditor
            >['initialValue']
          }
          handleAddComment={handleEditComment}
          isRoot={false}
          mode={EditorMode.Edit}
          setActiveComment={setActiveComment}
          onCancel={handleCancelEditing}
          editableComment={data}
          className="mt-5"
        />
      ) : (
        <>
          <div className="relative" ref={containerRef}>
            <div className="flex w-full flex-row items-start gap-2.5 sm:gap-2.5">
              {isRepliesOpen && (
                <div
                  ref={lineRef}
                  className="absolute left-[20px] top-[40px] w-[1px] bg-darkgray-100"
                ></div>
              )}
              <div className="relative">
                <img
                  className="h-10 w-10 rounded-full"
                  title={data.userName}
                  src={data.userAvatarUrl}
                />
                {level > 0 && (
                  <div className="absolute right-[39px] top-5 h-[1px] w-[30px] bg-darkgray-100"></div>
                )}
              </div>
              <div className="relative w-[calc(100%-40px)] min-w-0 grow-0 rounded-xl">
                <div
                  className={twJoin(
                    'font-AvertaPE relative rounded-xl bg-[#F0F3F6] p-[15px] transition-colors duration-[3000ms] ease-out',
                    (data.id === activeComment?.id || isHighlighted) && 'bg-blue/20',
                  )}
                  ref={commentRef}
                >
                  <div className="mb-3 flex items-center justify-between">
                    <CommentHeader
                      userName={data.userName}
                      userId={data.userId}
                      isCommunity={isCommunityComments}
                      commentTimestamp={commentTimestamp}
                      wasEdited={wasEdited}
                    />
                  </div>

                  <TextWithMentions
                    className="mt-1 text-darkblue/70 [word-wrap:break-word] md:mt-4 [&_a:hover]:underline [&_a]:text-[#2e6ef4] [&_a]:no-underline [&_p]:m-0 [&_p]:p-0"
                    text={data.text}
                    mentions={data.mentions}
                  />

                  <AttachmentsPreview className={twJoin(!!attachments?.length && 'pt-4')} />

                  {isCommentOwner && (
                    <CommentActions
                      commentData={data}
                      className="absolute right-3.5 top-3 justify-self-end"
                      onDelete={handleDeleteComment}
                      onDeleteAndBanUser={() => setIsDeleteAndBanUserOpened(true)}
                      onEdit={() => setIsEditing(true)}
                    />
                  )}
                </div>
                <div className="mt-1.5 flex translate-y-1 items-center gap-2 text-sm">
                  <Likes
                    handleLikeClick={handleLikeClick(data)}
                    isLiked={data.isLiked}
                    likesCount={data.likesCount}
                    commentId={data.id}
                    readOnly={Boolean(readOnly)}
                  />
                  {!readOnly && (
                    <div
                      className="cursor-pointer font-medium text-[#98A2B3] hover:text-[#5DC2FEFF]"
                      onClick={handleReply}
                    >
                      {t('comments.components.comments.reply_label')}
                    </div>
                  )}
                  {data.totalReplies > 0 && <EllipseIcon />}
                  {level === 0 && !!data.totalReplies && (
                    <div
                      className="mr-2 flex cursor-pointer text-[#5DC2FEFF]"
                      onClick={toggleReplies}
                    >
                      {isRepliesOpen
                        ? t('comments.components.comments.responses_label_hide')
                        : `${data.totalReplies} ${t(
                            'comments.components.comments.responses_label',
                            {
                              count: data.totalReplies,
                            },
                          )}`}
                    </div>
                  )}
                </div>
                {!isCommunityComments && !readOnly && activeComment?.id === data.id && (
                  <MessageBox
                    parentId={!Array.isArray(data.parent) ? data.parent.id : data.id}
                    setActiveComment={setActiveComment}
                    level={1}
                    onError={onError}
                    setResponses={(_, newReply) => {
                      const parentId = !Array.isArray(data.parent) ? data.parent.id : data.id
                      updateReplies(parentId, {
                        newReplies: [newReply, ...(repliesList[parentId]?.replies ?? [])],
                      })
                    }}
                  />
                )}
              </div>
            </div>

            {isRepliesOpen && (
              <div className="ml-[50px] mt-5 flex flex-col space-y-5 md:mt-6 md:space-y-6">
                {replies?.map(comment => (
                  <Comment
                    key={comment.id}
                    data={comment}
                    level={1}
                    setActiveComment={setActiveComment}
                    activeComment={activeComment}
                    onError={onError}
                  />
                ))}
                {!isEndReplies && !isLoadingReplies && (
                  <LoadMoreButton onClick={handleLoadMoreReplies}>
                    {t('comments.components.comments.load_more_label')}
                  </LoadMoreButton>
                )}
              </div>
            )}

            {isDeleteAndBanUserOpened && (
              <Confirmation
                onConfirm={deleteAndBanUser}
                onCloseModal={() => setIsDeleteAndBanUserOpened(false)}
                confirmationContent={
                  <RemoveRecentActivitiesCheckbox
                    label={t('settings.form.delete_comment_and_ban_user_confirmation.label')}
                    memberDisplayName={data.userName}
                    checked={removeRecentActivitiesChecked}
                    onCheck={() => setRemoveRecentActivitiesChecked(prev => !prev)}
                  />
                }
                errorMessageMap={{
                  unprocessableContentError: 'community.error.error_user_already_banned',
                }}
              />
            )}
          </div>
        </>
      )}
    </PostAttachmentsProvider>
  )
}
