/**
 * A fallback function to produce a date-time string in the format "DD/MM/YYYY, HH:MM:SS"
 * (24-hour clock) if `Intl.DateTimeFormat` is unavailable. This function does not handle
 * any specific time zone logic besides what the local environment provides.
 *
 * @function fallbackDateTimeFormat
 * @internal
 * @returns {string} A string formatted as "DD/MM/YYYY, HH:MM:SS".
 *
 * @example
 * // Example usage when Intl.DateTimeFormat is not supported
 * const exampleDate = new Date('2025-01-09T07:30:05Z');
 * const formattedString = fallbackDateTimeFormat(exampleDate);
 * console.log(formattedString);
 * // Possible output: "09/01/2025, 07:30:05"
 */
const fallbackDateTimeFormat = (date: Date): string => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // Pad with leading zeros to ensure two-digit format (e.g., "09" instead of "9").
  const dd = String(day).padStart(2, '0')
  const mm = String(month).padStart(2, '0')
  const hh = String(hours).padStart(2, '0')
  const min = String(minutes).padStart(2, '0')
  const sec = String(seconds).padStart(2, '0')

  return `${dd}/${mm}/${year}, ${hh}:${min}:${sec}`
}

/**
 * Converts a given timestamp to a human-readable date string in 'en-GB' format.
 *
 * @param {number} timestamp - The timestamp to format (in milliseconds since UNIX epoch).
 * @returns {string} A formatted date string in the format 'DD/MM/YYYY, HH:MM:SS'.
 *
 * @example
 * const readableDate = formatTimestampToReadableDate(1672531200000);
 * console.log(readableDate); // "31/12/2023, 23:00:00"
 */
export const formatTimestampToReadableDate = (timestamp: number): string => {
  const date = new Date(timestamp)

  if (typeof Intl === 'undefined' || typeof Intl.DateTimeFormat === 'undefined') {
    return fallbackDateTimeFormat(date)
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }
  return new Intl.DateTimeFormat('en-GB', options).format(date)
}
