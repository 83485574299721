import { DateUnitEnum } from 'shared/constants/date-time'

export type DateType = number | string | Date

/**
 * Calculates the difference between two dates in the specified unit.
 *
 * @param {DateType} firstDate - The first date to compare.
 * @param {DateType} secondDate - The second date to compare.
 * @param {keyof typeof DateUnitEnum} unit - The unit to measure the difference in (e.g., 'day', 'hour').
 * @returns {number} The time difference between the two dates in the specified unit.
 *
 * @example
 * // Difference in days
 * const diffInDays = getTimeDifference('2025-01-01', '2024-12-31', 'day');
 * console.log(diffInDays); // 1
 *
 * @example
 * // Difference in hours
 * const diffInHours = getTimeDifference(new Date(), new Date(Date.now() - 3600000), 'hour');
 * console.log(diffInHours); // 1
 */
export const getTimeDifference = (
  firstDate: DateType,
  secondDate: DateType,
  unit: keyof typeof DateUnitEnum,
): number => {
  const firstTime = new Date(firstDate).getTime()
  const secondTime = new Date(secondDate).getTime()

  const differenceInSeconds = (firstTime - secondTime) / 1000
  return differenceInSeconds / DateUnitEnum[unit]
}
