import { useSlate } from 'slate-react'
import { twMerge } from 'tailwind-merge'
import LinkIcon from 'shared/icons/link-icon'
import UnlinkIcon from 'shared/icons/unlink-icon'
import { ToolbarButton } from 'modules/comments/components/comment-editor/common/toolbar-button'
import { isBlockActive } from 'modules/community/utils/editor/core'
import { unwrapLink } from 'modules/community/utils/editor/link'

const LinkButton = ({ setLinkIsEditing }: { setLinkIsEditing: (value: boolean) => void }) => {
  const editor = useSlate()
  const linkActive = isBlockActive(editor, 'link')

  const handleClick = () => {
    if (linkActive) {
      unwrapLink(editor)
    } else {
      setLinkIsEditing(true)
    }
  }

  return (
    <ToolbarButton
      format={linkActive ? 'unlink' : 'link'}
      active={linkActive}
      onClick={handleClick}
    >
      {linkActive ? (
        <UnlinkIcon
          className={twMerge('size-5 text-[#8995b0b3] md:size-6', linkActive && 'text-[initial]')}
        />
      ) : (
        <LinkIcon
          className={twMerge('size-5 text-[#8995b0b3] md:size-6', linkActive && 'text-[initial]')}
        />
      )}
    </ToolbarButton>
  )
}

export default LinkButton
