import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'

export interface CommentsCountType {
  commentsCount: number
  communityPostId: number
}

export const getCommentsCountKey = (communityPath: string, postsIds: number[]): string => {
  const searchParams = new URLSearchParams()
  postsIds.forEach(postId => searchParams.append('ids[]', String(postId)))

  return `${COMMUNITY_API_PREFIX}/${communityPath}/posts/comments-count?${searchParams.toString()}`
}

interface UseCommentsCountOptions {
  communityPath?: string
  postsIds?: number[]
  config?: SWRConfiguration<CommentsCountType[], Error>
}

export const useCommentsCount = ({ communityPath, postsIds, config }: UseCommentsCountOptions) => {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, CommentsCountType[]>(
    {
      method: RequestMethodsEnum.get,
    },
  )

  return useSWR<CommentsCountType[], Error>(
    () => {
      if (communityPath && postsIds) {
        return getCommentsCountKey(communityPath, postsIds)
      }
      return null
    },
    fetcher,
    config,
  )
}
