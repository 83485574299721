import {
  AttachmentTypeEnum,
  PostAttachmentType,
  PostAttachmentsApiCreateInterface,
} from '../types/post-attachment'

export const escapeAttachmentLocalFields = (
  attachments: PostAttachmentType[] = [],
): PostAttachmentsApiCreateInterface[] =>
  attachments.map(attachment => {
    switch (attachment.type) {
      case AttachmentTypeEnum.Image:
      case AttachmentTypeEnum.Video:
        return {
          type: attachment.type,
          dataFileId: attachment.dataFileId,
        }
      case AttachmentTypeEnum.EmbeddedVideo:
        return {
          type: attachment.type,
          url: attachment.url,
          previewUrl: attachment.previewUrl,
        }
      case AttachmentTypeEnum.EmbeddedGif:
        return {
          type: attachment.type,
          url: attachment.url,
          previewUrl: attachment.previewUrl,
        }
      case AttachmentTypeEnum.Audio:
        return {
          type: attachment.type,
          dataFileId: attachment.dataFileId,
        }
      default:
        return attachment
    }
  })

export const getPrimaryAttachment = (
  attachments?: PostAttachmentType[],
): PostAttachmentType | null => attachments?.[0] ?? null
