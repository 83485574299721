import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor } from 'slate-react'
import { Editor, Range } from 'slate'
import { Loader } from 'shared/components/loader'
import { Suggestion } from 'modules/comments/components/comment/comment.types'
import { ListBox } from 'modules/comments/ui/ListBox/ListBox'
import styles from './SuggestionMention.module.css'

interface MentionSuggestionsProps {
  target: Range | null
  editor: Editor
  items: Suggestion[]
  onSelect: (item: Suggestion) => void
  isLoading: boolean
}

const transformMentionsToSuggestions = (mentions: Suggestion[]) =>
  mentions.map(mention => ({
    value: mention.userId.toString(),
    label: (
      <div className={styles.Suggestion}>
        {mention.profileImageUrl && (
          <img
            className={styles.Avatar}
            width={20}
            height={20}
            src={mention.profileImageUrl}
            alt={`Avatar of ${mention.displayName}`}
          />
        )}
        <strong className={styles.DisplayName}>{mention.displayName}</strong>
      </div>
    ),
  }))

export const MentionSuggestions: React.FC<MentionSuggestionsProps> = ({
  target,
  editor,
  items,
  onSelect,
  isLoading,
}) => {
  const [listItems, setListItems] = useState<{ label: React.ReactNode; value: string }[]>([])

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  )

  const setRefElement = useRef(setReferenceElement)

  useEffect(() => {
    if (target && items.length > 0) {
      const listItems = transformMentionsToSuggestions(items)
      setListItems(listItems)
    }
  }, [target, items])

  useEffect(() => {
    if (target) {
      const el = document.createElement('div')
      document.body.appendChild(el)
      setRefElement.current(el)
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.position = 'absolute'
      el.style.top = `${rect.bottom + window.pageYOffset}px`
      el.style.left = `${rect.left + window.pageXOffset}px`

      return () => {
        document.body.removeChild(el)
        setRefElement.current(null)
      }
    }
  }, [target, editor])

  if (!target) return null

  return (
    <div ref={setReferenceElement}>
      <div
        ref={setPopperElement}
        style={{ zIndex: 100, ...popperStyles.popper }}
        {...popperAttributes.popper}
      >
        <div className={styles.Popper}>
          {!isLoading || items.length > 0 ? (
            <ListBox
              options={listItems}
              onChange={userId => {
                const suggestion = items.find(item => item.userId === Number(userId))
                if (suggestion) {
                  onSelect(suggestion)
                }
              }}
            />
          ) : (
            <div className={styles.LoaderContainer}>
              <Loader type="light" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
