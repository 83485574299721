import React, { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import GIFIcon from 'shared/icons/gif-icon'

interface GifPickerButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  iconClassName?: string
  setReferenceElement: (element: HTMLButtonElement | null) => void
  onToggle: () => void
  isOpen: boolean
}

export const GifPickerButton: FC<GifPickerButtonProps> = ({
  setReferenceElement,
  onToggle,
  isOpen,
  iconClassName,
  ...rest
}) => {
  return (
    <button
      ref={setReferenceElement}
      onClick={onToggle}
      type="button"
      aria-label="Toggle GIF Picker"
      aria-expanded={isOpen}
      {...rest}
    >
      <GIFIcon className={(twMerge(`h-6 w-6`), iconClassName)} />
    </button>
  )
}
