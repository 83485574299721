/**
 * Extracts the filename from a URL
 * @param url - The URL to extract the filename from
 * @returns The extracted filename or 'image' as fallback
 *
 * @example
 * // Returns "file.jpg"
 * getFilenameFromUrl("https://example.com/path/file.jpg")
 *
 * // Returns "document"
 * getFilenameFromUrl("https://example.com/path/document?query=value")
 *
 * // Returns "image" if URL is invalid or has no filename
 * getFilenameFromUrl("invalid-url")
 */
export const getFilenameFromUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url, window.location.href)
    return parsedUrl.pathname.split('/').pop()?.split('?')[0] || 'attachment'
  } catch (error) {
    return 'attachment'
  }
}
