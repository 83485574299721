/**
 * Maps a MIME type to its corresponding file extension
 *
 * @param mimeType - The MIME type string to convert to a file extension
 * @returns The file extension (including the dot) that corresponds to the MIME type,
 *          or '.jpg' as fallback if the MIME type is not recognized
 * @example
 * getExtensionFromMimeType('image/png') // Returns '.png'
 * getExtensionFromMimeType('image/unknown') // Returns '.jpg'
 */
export const getExtensionFromMimeType = (mimeType: string) => {
  const mapping = {
    // Images
    'image/jpeg': '.jpg',
    'image/pjpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/webp': '.webp',
    'image/bmp': '.bmp',
    'image/tiff': '.tiff',
    'image/svg+xml': '.svg',
    'image/x-icon': '.ico',

    // Videos
    'video/mp4': '.mp4',
    'video/webm': '.webm',
    'video/ogg': '.ogv',
    'video/mpeg': '.mpeg',
    'video/quicktime': '.mov',
    'video/x-msvideo': '.avi',
    'video/x-ms-wmv': '.wmv',

    // Audio
    'audio/mpeg': '.mp3',
    'audio/wav': '.wav',
    'audio/x-wav': '.wav',
    'audio/ogg': '.ogg',
    'audio/webm': '.weba',
    'audio/flac': '.flac',
    'audio/aac': '.aac',
    'audio/3gpp': '.3gp',
    'audio/amr': '.amr',
    'audio/opus': '.opus',
  }
  return mapping[mimeType as keyof typeof mapping] || '.jpg'
}
