import { DATE_CUTOFFS, TIME_UNITS } from 'shared/constants/date-time'

/**
 * Generates a short, localized label representing the time elapsed since the given date.
 *
 * @param {Date} date - The date to compare with the current time.
 * @param {Function} translate - A translation function to provide localized short labels.
 * @returns {string} A short, localized label (e.g., '5d', '2h', 'just now').
 *
 * @example
 * const label = getShortTimeLabel(new Date(Date.now() - 86400000), translate);
 * console.log(label); // "1d" (if SHORT_TIME_LOCO.d = "d")
 */
export const getShortTimeLabel = (date: Date, translate: any): string => {
  const intervalInSeconds = Math.abs((new Date().getTime() - date.getTime()) / 1000)

  const unitIndex = DATE_CUTOFFS.findIndex(cutoff => cutoff > intervalInSeconds)
  const divisor = unitIndex ? DATE_CUTOFFS[unitIndex - 1] : 1

  const value = Math.floor(intervalInSeconds / divisor)

  // If the interval is 0, or time is less than a minute, return 'just now'
  if (value === 0 || unitIndex === 0) {
    return translate(TIME_UNITS[0].shortLabel)
  }

  return `${value}${translate(TIME_UNITS[unitIndex].shortLabel)}`
}
